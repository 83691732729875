import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom"; 
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

const Messenger = () => {
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [selectedTracks, setSelectedTracks] = useState([]);
  const [trackIds, setTrackIds] = useState([]);
  const [link, setLink] = useState("");
  const [linkTitle, setLinkTitle] = useState("");
  const [addLink, setAddLink] = useState(false);
  const [messageParagraphs, setMessageParagraphs] = useState([""]);
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const expiresIn = localStorage.getItem("expiresIn");

    if (!token) {
      navigate("/sign-in");
    } else if (expiresIn) {
      const expirationDate = new Date(expiresIn);
      if (expirationDate <= new Date()) {
        localStorage.removeItem("token");
        localStorage.removeItem("expiresIn");
        navigate("/sign-in");
      }
    } else {
        const fetchTracks = async () => {
            try {
              const response = await fetch("http://141.136.36.87:9020/api/v1/tracks");
              if (!response.status) {
                  toast.error("Failed to load tracks.");
              }
              const data = await response.json();
              if (data.data && data.data.tracks) {
                setTracks(data.data.tracks);
              } else {
                toast.error("No tracks found.");
              }
            } catch (error) {
              toast.error("Failed to load tracks.");
            }
        }

        fetchTracks();
    
    };

    
  }, [navigate]);

  const handleTrackChange = (track) => {
    if (track.track === "All") {
      if (selectedTracks.includes("All")) {
        setSelectedTracks((prev) => prev.filter((t) => t !== "All"));
        setTrackIds([]);
      } else {
        setSelectedTracks(["All"]);
        setTrackIds([]);
      }
    } else {
      if (selectedTracks.includes("All")) {
        toast.error("Cannot select other tracks when 'All' is selected.");
        return;
      }

      setSelectedTracks((prev) =>
        prev.includes(track.track) ? prev.filter((t) => t !== track.track) : [...prev, track.track]
      );

      const trackId = track.id;
      setTrackIds((prev) => 
        !prev.includes(trackId) ? [...prev, trackId] : prev
      );
    }
  };

  const handleAddParagraph = () => {
    setMessageParagraphs((prev) => [...prev, ""]);
  };

  const handleParagraphChange = (index, value) => {
    const updatedParagraphs = [...messageParagraphs];
    updatedParagraphs[index] = value;
    setMessageParagraphs(updatedParagraphs);
  };

  const handleDeleteParagraph = (index) => {
    const updatedParagraphs = messageParagraphs.filter((_, i) => i !== index);
    setMessageParagraphs(updatedParagraphs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!subject) {
      toast.error("Please enter a subject.");
      return;
    }
  
    const filledParagraphs = messageParagraphs.filter(p => p.trim() !== "");
    if (filledParagraphs.length === 0) {
      toast.error("Please fill in at least one paragraph.");
      return;
    }
  
    if (messageParagraphs.length > 1 && filledParagraphs.length < messageParagraphs.length) {
      toast.error("All paragraphs must be filled.");
      return;
    }
  
    if (addLink && (!link || !linkTitle)) {
      toast.error("Please fill in both link and link title.");
      return;
    }
  
    if (selectedTracks.length === 0) {
      toast.error("Please select at least one track.");
      return;
    }
  
    const all = selectedTracks.includes("All");
  
    const payload = {
      subject,
      all,
      trackIds,
      button: addLink,
      link,
      buttonTitle: linkTitle,
      messages: filledParagraphs,
    };
  
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("You are not logged in.");
      navigate("/sign-in");
      return;
    }

    try {
      const response = await fetch("http://141.136.36.87:9020/api/v1/message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
  
      const data = await response.json();
  
      if (response.status === 200) {
        toast.success(data.message);
        setSubject("");
        setSelectedTracks([]);
        setTrackIds([]);
        setAddLink(false);
        setLink("");
        setLinkTitle("");
        setMessageParagraphs([""]);
      } else {
        toast.error(data.error || "An unexpected error occurred.");
      }
    } catch (error) {
      toast.error("Failed to send message.");
    }
  };

  return (
    <div className="messenger-page">

      <ToastContainer />
      <h1 className="page-title">Messenger</h1>
      <form onSubmit={handleSubmit} className="messenger-form">
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="input-field"
        />
        <div className="track-selection">
          <h2>Select Tracks:</h2>
          {tracks.map((track) => (
            <label key={track.id} className="track-option">
              <input
                type="checkbox"
                checked={selectedTracks.includes(track.track)}
                onChange={() => handleTrackChange(track)}
                disabled={track.track === "All" && selectedTracks.includes("All") && selectedTracks.length > 0}
              />
              {track.track}
            </label>
          ))}
          <label key="All" className="track-option">
            <input
              type="checkbox"
              checked={selectedTracks.includes("All")}
              onChange={() => handleTrackChange({ track: "All" })}
            />
            All
          </label>
        </div>
        <label className="link-toggle">
          <input
            type="checkbox"
            checked={addLink}
            onChange={() => setAddLink(!addLink)}
          />
          Add Link
        </label>
        {addLink && (
          <div className="link-inputs">
            <input
              type="text"
              placeholder="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="input-field"
            />
            <input
              type="text"
              placeholder="Link Title"
              value={linkTitle}
              onChange={(e) => setLinkTitle(e.target.value)}
              className="input-field"
            />
          </div>
        )}
        <div className="message-section">
          <h2>Message:</h2>
          {messageParagraphs.map((paragraph, index) => (
            <div key={index} className="paragraph-container">
              <textarea
                placeholder={`Paragraph ${index + 1}`}
                value={paragraph}
                onChange={(e) => handleParagraphChange(index, e.target.value)}
                className="message-textarea"
              />
              <button
                type="button"
                onClick={() => handleDeleteParagraph(index)}
                className="delete-paragraph-button"
              >
                Delete
              </button>
            </div>
          ))}
          <button type="button" onClick={handleAddParagraph} className="add-paragraph-button">Add Paragraph</button>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default Messenger;