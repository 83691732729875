import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import Messenger from "./Messenger";
import "./App.css";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/" element={<Messenger />} />
      </Routes>
    </div>
  );
}

export default App;